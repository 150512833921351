// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    rememberMe: true,
    secret: 'basic',
    pageSizes: [25, 50, 100],
    InterceptorSkipHeader: 'X-Skip-Interceptor',
    // apiUrl: 'https://bludotapidev.quinns.pw/',
    apiUrl: 'https://bludotapiuat.quinns.pw/',
    publicUrl: 'localhost:4200',
    // dcSpacesUrl: 'https://bludot.ams3.digitaloceanspaces.com/dev/',
    // websocketUrl: 'https://bludotapidev.quinns.pw/',
    dcSpacesUrl: 'https://bludot.ams3.digitaloceanspaces.com/uat/',
    websocketUrl: 'https://bludotapiuat.quinns.pw/',
    keys: {
        accessToken: 'basic_token',
        username: 'basic_u',
        password: 'basic_p'
    }
};
